const Subsections = {
  appendix: [
    [
      { attribute: "reference", height: "45%", showSectionTitle: true },
      { attribute: "glossary", height: "55%", showSectionTitle: true },
    ],
    [{ attribute: "glossary", height: "100%" }],
    [{ attribute: "abbreviation", height: "100%", showSectionTitle: true }],
  ],
};
const SectionTitle = {
  doc_history: { title: "Document History", position: -2 },
  doc_control: { title: "Document Control", position: -1 },
  copyright: { title: "Copyright Information", position: 0 },
  tbl_of_content: { title: "CONTENTS" },
  purpose: { title: "Purpose", slNo: "1" },
  scope: { title: "Scope", slNo: "2" },
  organisation_identifier: {
    title: `Organisation Identifier: "[[OrgShortname]]":`,
    slNo: "3",
  },
  common_doc_type: { title: "", slNo: "4" },
  enforcement: { title: "Enforcement", slNo: "5" },
  exception: { title: "Exception", slNo: "6" },
  violation: { title: "Violation", slNo: "7" },
  review: { title: "Review", slNo: "8" },
  appendix: { title: "Appendix", slNo: "9" },
  reference: { title: "Reference", slNo: "9.1" },
  glossary: { title: "Glossary & Definition", slNo: "9.2" },
  abbreviation: { title: "Abbreviation, Acronym, Initialism", slNo: "9.3" },
  change_history: { title: "Change History" },
};
const ChangeHistory = { title: "Change History", attribute: "change_history" };

const ActiveVariables = [
  { variable: "[[OrgnName]]" },
  { variable: "[[OrgnNameAPST]]" },
  { variable: "[[OrgnFullname]]" },
  { variable: "[[OrgnDomain]]" },
  { variable: "[[OrgnWebsite]]" },
  { variable: "[[OrgnCEOFullname]]" },
  { variable: "[[DcmtDept]]" },
  { variable: "[[DcmtType]]" },
  { variable: "[[DcmtStatus]]" },
  { variable: "[[DcmtID]]" },
  { variable: "[[DcmtTitle]]" },
  { variable: "[[DcmtFilename]]" },
  { variable: "[[DcmtVrsnContent]]" },
  { variable: "[[DcmtVrsnPresentation]]" },
  { variable: "[[DcmtContentPurpose]]" },
  { variable: "[[DcmtContentScope]]" },
  { variable: "[[DcmtContentIntroduction]]" },
  { variable: "[[DcmtContentDirective]]" },
  { variable: "[DcmtContentReference]]" },
];
const doVaribleMap = (org, document, sectionHTML) => {
  let sectionHTMLCopy = String(sectionHTML);
  ActiveVariables.forEach(({ variable }) => {
    let varHTML = "",
      val,
      addS;
    switch (variable) {
      case "[[OrgnName]]":
        val = org.brandname;
        varHTML = `<span style="text-transform: capitalize">${val}</span>`;
        break;
      case "[[OrgnNameAPST]]":
        val = String(org.brandname).toLowerCase();
        addS =
          val.endsWith("s") ||
          val.endsWith("se") ||
          val.endsWith("sh") ||
          val.endsWith("ce");
        val += "'";
        if (!addS) {
          val += "s";
        }
        varHTML = `<span style="text-transform: capitalize">${val}</span>`;
        break;
      case "[[OrgnFullname]]":
        val = String(org.legalname).toLowerCase();
        val.replace("pvt", "Private");
        val.replace("ltd", "Limited");
        varHTML = `<span style="text-transform: capitalize">${val}</span>`;
        break;
      case "[[OrgnDomain]]":
        val = String(org.email_domain).toLowerCase();
        varHTML = `@${val}`;
        break;
      case "[[OrgnWebsite]]":
        val = org.website;
        varHTML = `<a href="${val}" style="text-decoration:none">${val}</a>`;
        break;
      case "[[DcmtDept]]":
        val = document.department_name;
        varHTML = typeof val === "object" ? val.label : val;
        break;
      case "[[DcmtType]]":
        val = document.doc_type;
        val = typeof val === "object" ? val.label : val;
        varHTML = `<span style="text-transform: capitalize">${val}</span>`;
        break;
      case "[[DcmtStatus]]":
        val = document.status;
        val = typeof val === "object" ? val.label : val;
        varHTML = `<span style="text-transform: capitalize">${val}</span>`;
        break;
      case "[[DcmtID]]":
        varHTML = document.doc_id;
        break;
      case "[[DcmtTitle]]":
        varHTML = document.name;
        break;
      case "[[DcmtFilename]]":
        varHTML = `${document.doc_id}-${document.name}`;
        break;
      case "[[DcmtVrsnContent]]":
        varHTML = document.version;
        break;
      case "[[DcmtVrsnPresentation]]":
        varHTML = document.presentation ? document.presentation.version : "";
        break;
      case "[[DcmtContentPurpose]]":
        break;
      case "[[DcmtContentScope]]":
        break;
      case "[[DcmtContentIntroduction]]":
        break;
      case "[[DcmtContentDirective]]":
        break;
      case "[[DcmtContentReference]]":
        break;
      default:
        break;
    }
    if (varHTML) {
      sectionHTMLCopy = sectionHTMLCopy.replaceAll(variable, varHTML);
    }
  });
  return sectionHTMLCopy;
};
const getTableContentSpaces = () => {
  let spaces = "&nbsp;";
  for (let i = 0; i < 12; i++) {
    spaces += "&nbsp;";
  }
  return spaces;
};
const fillTableOfContents = (pages) => {
  var pageHTMLString = ``,
    index;
  for (let i = 0; i < pages.length; i++) {
    for (let j = 0; j < pages[i].length; j++) {
      const section = pages[i][j];
      const { attribute, showSectionTitle, isSubSection } = section;
      if (attribute !== "tbl_of_content") {
        if (showSectionTitle) {
          let isFirstSec =
            attribute === "doc_history" ||
            attribute === "doc_control" ||
            attribute === "copyright";
          pageHTMLString += `<div class='w-100 row tbl-cont-row h-btn ${
            isSubSection ? "sub-section" : ""
          }'>`;
          pageHTMLString += `<a href='#section-${attribute}' class='${
            isSubSection ? "f9" : "f8"
          } c0133CC text caps ${isFirstSec ? "first" : "bold"}'><span>${
            section.title
          }</span></a>`;
          pageHTMLString += `<a href='#page-${i + 1}' class='f8 c0133CC ${
            !isFirstSec && !isSubSection ? "bold" : ""
          } text page-no'><span>${i + 1}</span></a>`;
          pageHTMLString += "</div>";
        }
      } else {
        if (!index) {
          index = i;
        }
      }
    }
  }
  if (index > -1) {
    pages[index][0].value = pageHTMLString;
  }
};
const fillChangeHistory = (pages, document, isSchemaOnly) => {
  //remove change History
  pages.forEach((page, i) => {
    page.forEach((section, j) => {
      if (section.attribute === "change_history") {
        pages[i].splice(j, 1);
      }
    });
    if (pages[i].length === 0) {
      pages.splice(i, 1);
    }
  });
  // add change history
  if (
    document &&
    Array.isArray(document.change_history) &&
    document.change_history.length > 0
  ) {
    let history = [...document.change_history],
      i = 0;
    while (i <= history.length) {
      let chunk = history.slice(i, 30);
      pages.push([
        {
          ...ChangeHistory,
          showSectionTitle: i === 0,
          value: chunk,
        },
      ]);
      i += 30;
    }
  } else if (isSchemaOnly) {
    pages.push([
      {
        ...ChangeHistory,
        showSectionTitle: true,
        value: [],
      },
    ]);
  }
};
const getDocumentConfig = (document, sections, docType, presentation) => {
  let pageConfig, presAppendix;
  const getMatchingKey = (attribute) => {
    const keysEndingWithId = presAppendix.filter((key) =>
      key.endsWith(attribute)
    );
    return keysEndingWithId;
  };
  if (document.page_config) {
    pageConfig = JSON.parse(document.page_config);
    if (docType === "content") {
      presAppendix = Object.keys(document.appendix);
      presAppendix.sort();
      console.log("pageConfig", pageConfig);
      let index = pageConfig.findIndex((page) => {
        return (
          page &&
          page.map((section) => section.mainAttribute).includes("appendix")
        );
      });
      pageConfig.splice(index);
      const preConfig = JSON.parse(presentation.page_config);
      index = preConfig.findIndex((page) => {
        return (
          page &&
          page.map((section) => section.mainAttribute).includes("appendix")
        );
      });
      const presAppendixPages = preConfig.splice(index);
      let prevSection = null;
      for (let i = 0; i < presAppendixPages.length; i++) {
        const page = presAppendixPages[i];
        if (page) {
          pageConfig.push([]);
          let pageNo = pageConfig.length - 1;
          // eslint-disable-next-line no-loop-func
          page.forEach((sec) => {
            let newConfig = {};
            if (i === 0) {
              if (sec.onlyTitle) {
                newConfig = sec;
              } else {
                const docKey = getMatchingKey(sec.attribute);
                newConfig = {
                  ...sec,
                  key: docKey[0],
                  presKey: sec.key,
                  presentation: true,
                  content: sec.attribute === "reference",
                };
              }
              pageConfig[pageNo].push(newConfig);
            } else {
              const isabbreviation = sec.attribute === "abbreviation";
              let docKeys;
              if (isabbreviation) {
                docKeys = getMatchingKey("glossary");
                docKeys.forEach((docKey) => {
                  if (!pageConfig[pageNo]) {
                    pageConfig[pageNo] = [];
                  }
                  pageConfig[pageNo].push({
                    ...prevSection,
                    content: true,
                    presentation: false,
                    key: docKey,
                  });
                  pageNo++;
                });
              }
              if (!pageConfig[pageNo]) {
                pageConfig[pageNo] = [];
              }
              docKeys = getMatchingKey("abbreviation");
              newConfig = {
                ...sec,
                presKey: sec.key,
                presentation: true,
                content: isabbreviation,
                key: isabbreviation ? docKeys[0] : "",
              };
              pageConfig[pageNo].push(newConfig);
              prevSection = sec;
            }
          });
        }
      }
    }
  } else {
    pageConfig = [];
    sections.forEach(({ attribute, defaultPage }) => {
      if (!pageConfig[defaultPage]) pageConfig[defaultPage] = [];
      const subsectionsPages = getSubsections(attribute);
      const hasSubsections = Array.isArray(subsectionsPages);
      if (hasSubsections) {
        pageConfig[defaultPage].push({
          onlyTitle: true,
          attribute,
          showSectionTitle: true,
        });
        if (docType === "content" && presentation) {
          const preConfig = JSON.parse(presentation.page_config);
          let pageOffset = 0,
            lastIndex = {};
          preConfig.forEach((preSections) => {
            Array.isArray(preSections) &&
              preSections.forEach((preSec, index) => {
                if (preSec.mainAttribute === attribute) {
                  let pageNo = defaultPage + pageOffset;
                  if (!pageConfig[pageNo]) pageConfig[pageNo] = [];
                  let subsection = { ...preSec };
                  subsection.presKey = preSec.key;
                  pageConfig[pageNo].push(subsection);
                  lastIndex[preSec.attribute] = { pageNo, index };
                  if (index === preSections.length - 1) {
                    pageOffset++;
                  }
                }
              });
          });
          Object.values(lastIndex).forEach(({ pageNo, index }) => {
            pageConfig[pageNo][index].content = true;
          });
          return;
        }
        subsectionsPages.forEach((pageSections, index) => {
          if (Array.isArray(pageSections)) {
            let pageNo = defaultPage + index;
            if (!pageConfig[pageNo]) pageConfig[pageNo] = [];
            pageSections.forEach((_) => {
              let subsection = {
                ..._,
                level: _.showSectionTitle ? 1 : 2,
                isSubSection: true,
                mainAttribute: attribute,
                presentation: true,
              };
              pageConfig[pageNo].push(subsection);
            });
          }
        });
      } else {
        pageConfig[defaultPage].push({
          attribute,
          showSectionTitle: true,
          hasSubsections,
        });
      }
    });
  }
  return pageConfig;
};
export const getSubsections = (attribute) => {
  return Subsections[attribute];
};
export const getSectionInfo = (iOptions) => {
  const { isSchemaOnly, user, document, attribute } = iOptions;
  let sectionInfo = SectionTitle[attribute];
  let title = "";
  if (sectionInfo) {
    title = sectionInfo.title;
  }
  if (attribute === "common_doc_type") {
    title = document.doc_type ? document.doc_type.label : "[[DocumentType]]";
  } else if (attribute === "organisation_identifier" && !isSchemaOnly) {
    title = +` "${user.ent_org.brandname}"`;
  } else if (attribute === ChangeHistory.attribute) {
    title = ChangeHistory.title;
  }
  return { title: title, slNo: sectionInfo.slNo };
};
export const getDocumenterPages = (iOptions) => {
  const {
    docType,
    document,
    user,
    isSchemaOnly,
    sections,
    activePresentation,
  } = iOptions || {};
  const presentation = document.presentation || {};
  let pageConfig = getDocumentConfig(
    document,
    sections,
    docType,
    activePresentation
  );
  let pageCounts = pageConfig.length;
  let pageSections = [];
  const getPresentationValue = (attribute) => {
    let value = presentation[attribute] || "";
    if (value) {
      if (presentation.page_config) {
        value = JSON.parse(value);
        value = Object.values(value).join();
      }
    }
    return value;
  };
  for (let i = 0; i < pageCounts; i++) {
    let currentPageSections = [];
    let currentPageConfig = pageConfig && pageConfig[i];
    if (currentPageConfig) {
      for (let j = 0; j < currentPageConfig.length; j++) {
        const sectionConfig = currentPageConfig[j];
        if (sectionConfig) {
          const {
            attribute,
            showSectionTitle,
            key,
            onlyTitle,
            mainAttribute,
            isSubSection,
          } = sectionConfig;
          let val = document[isSubSection ? mainAttribute : attribute];
          if (
            !onlyTitle &&
            attribute !== "doc_history" &&
            attribute !== "change_history"
          ) {
            val = val && val[key] ? val[key] : "";
            val = `<span style="font-family:Roboto;font-size:14px">${
              val || ""
            }</span>`;
          }
          let sectionTitleInfo = showSectionTitle
            ? getSectionInfo({ document, attribute, isSchemaOnly, user })
            : {};
          let result = { ...sectionTitleInfo, ...sectionConfig, value: val };
          if (
            docType === "content" &&
            isSubSection &&
            activePresentation &&
            result.presentation &&
            !result.presKey
          ) {
            result.presKey = getMatchingKey(
              activePresentation[mainAttribute],
              j,
              result.attribute
            );
          }
          currentPageSections.push(result);
        }
      }
      pageSections.push(currentPageSections);
    } else {
      currentPageSections = sections.filter(
        (section) => section.defaultPage === i
      );
      currentPageSections = currentPageSections.map((_section) => {
        let section = { ..._section },
          docValue = document[section.attribute],
          preVal = getPresentationValue(section.attribute),
          newVal = "";
        switch (section.attribute) {
          case "tbl_of_content":
            section.showSectionTitle = true;
            section.allowAddVariable = false;
            break;
          case "copyright":
          case "doc_control":
            newVal = preVal;
            break;
          case "common_doc_type":
            section.title = isSchemaOnly
              ? "[[DcmtType]]"
              : document.doc_type.label;
            newVal = preVal;
            break;
          case "doc_history":
            newVal = docValue;
            break;
          default:
            if (section.attribute === "organisation_identifier" && user) {
              section.title += ` "${
                isSchemaOnly ? "[[OrgnName]]" : user.ent_org.brandname
              }"`;
            }
            if (preVal) {
              newVal += preVal;
            }
            if (docValue) {
              newVal += docValue;
            }
            break;
        }
        if (!isSchemaOnly && section.attribute !== "doc_history") {
          newVal = `<span style="font-family:Roboto;font-size:14px">${
            newVal || ""
          }</span>`;
          newVal = doVaribleMap(user.ent_org, document, newVal);
        }
        return { ...section, showSectionTitle: true, value: newVal };
      });
      pageSections.push(currentPageSections);
    }
  }
  if (docType === "presentation") {
    fillChangeHistory(pageSections, document, isSchemaOnly);
    fillTableOfContents(pageSections);
  }
  return pageSections;
};
export const getTextFromHTML = (iHTMLString) => {
  const parser = new DOMParser();
  iHTMLString = (iHTMLString || "").replace("&shy;", "");
  const doc = parser.parseFromString(iHTMLString, "text/html");
  return (doc.body.textContent || "").replace(/\n/g, "");
};
export const getMatchingKey = (iObject, sectionIndex, attribute) => {
  let presKey = Object.keys(iObject).find((key) =>
    key.endsWith(`${sectionIndex}_${attribute}`)
  );
  return presKey;
};
export const getSectionPosition = (attribute) => {
  let position = -3;
  let info = SectionTitle[attribute];
  if (info) {
    position = Number(info.position || info.slNo);
  }
  return position;
};
